import { Component } from 'vue-property-decorator'
import { GForm } from '@/components/forms/GForm'

@Component
export class LeadActivityView extends GForm {
  leadActivityStatus = {
    pending: null,
    closed: null,
  }

  activityResultItems = []

  leadActivityClosingReason = {
    success: null,
    canceled: null,
    withoutDeal: null,
  }

  async mounted () {
    this.leadActivityStatus.closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    this.leadActivityClosingReason.success = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'lead_activity' } } } },
        ],
      },
    })

    this.leadActivityClosingReason.canceled = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'canceled' } } },
          { status: { process: { table_name: { _eq: 'lead_activity' } } } },
        ],
      },
    })

    this.leadActivityClosingReason.withoutDeal = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'without_deal' } } },
          { status: { process: { table_name: { _eq: 'lead_activity' } } } },
        ],
      },
    })

    this.activityResultItems = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { status: { process: { table_name: { _eq: 'lead_activity' } } } },
    })
  }
}

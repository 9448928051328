
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import DialogContent from '@/components/core/baseDialog/DialogContent.vue'

@Component({
  components: { DialogContent },
})
  export default class FormDialog extends Vue {
  @Prop({ type: Boolean, default: false }) enable!: boolean;
  @Prop({ type: Boolean, default: false }) grow!: boolean;
  @Prop({ type: Number, default: 0 }) height!: number;
  }
